<template>
  <HeaderService
      :title="'Аутсорсинг бухгалтерии.'"
      :info="header.words"
      :person="header.person"
  />
  {{ $route.params.section }}
  <div class="finances">
    <we-work-with
        :title="'Индустрии, с которыми мы работаем'"
        :subtitle="'Знаем специфику бухгалтерии и финансов в разных сферах.'"
    />
    <about-clients
        id="clients"
        :reverse="'reverse'"
        :title='`Наши клиенты — <span class="text-gradient">лидеры IT-индустрии</span>`'
        :clients="clients"
        :bubbles="bubbles"
    />
    <help-slider :title="helpSlides.title" :data="helpSlides.slides"/>
    <finances-plans-table id="price"/>
    <call-to-action
        :actionTitle="'Соберём для вас индивидуальное предложение'"
        :msg="'Проанализируем ваши задачи, подскажем оптимальную структуру и обеспечим организацию пространств для решения всех ваших задач под ключ.'"
        :btn-text="'Получить консультацию'"
    />
    <we-do-it-right
        :info="weDoIt.info"
        :blocks="weDoIt.blocks"
        :dude="'calm_dude.svg'"
        :bg="'green'"
    />
    <call-to-action
        :actionTitle="'Соберём для вас индивидуальное предложение'"
        :msg="'Проанализируем ваши задачи, подскажем оптимальную структуру и обеспечим организацию пространств для решения всех ваших задач под ключ.'"
        :btn-text="'Получить консультацию'"
        :hide="true"
    />
    <team-block
        id="team"
        :title='`Команда, которая <span class="text-gradient">умеет всё</span>`'
        :subtitle="'Всю работу за вас выполнит команда разнопрофильных специалистов. Ни один специалист не будет эффективнее целой команды.'"
        :team="team"
        :team-name="'finances'"
    />
    <business-tasks-block id="cases" :cases="cases"/>
    <best-conditions/>
  </div>
</template>

<script>
import WeWorkWith from "@/components/WeWorkWith";
import AboutClients from "@/components/AboutClients";
import CallToAction from "@/components/CallToAction";
import HelpSlider from "@/components/HelpSlider";
import BusinessTasksBlock from "@/components/BusinessTasksBlock";
import TeamBlock from "@/components/TeamBlock";
import BestConditions from "@/components/BestConditions";
import WeDoItRight from "@/components/WeDoItRight";
import FinancesPlansTable from "@/components/FinancesPlansTable";
import HeaderService from "@/components/HeaderService";

export default {
  name: "Finances",
  props: {
    section: {
      type: String,
      required: false
    }
  },
  components: {
    HeaderService,
    WeWorkWith,
    AboutClients,
    CallToAction,
    HelpSlider,
    BusinessTasksBlock,
    TeamBlock,
    BestConditions,
    WeDoItRight,
    FinancesPlansTable
  },
  data() {
    return {
      header: {
        person: {
          name:'Елена Юманова<br>Руководитель i-Space. Finance',
          img: 'umanova.png'
        },
        words: [
          {
            title: 'Все включено',
            content: 'Бухгалтерия, налоги, кадры, льготы'
          },
          {
            title: 'Индивидуальный подход',
            content: 'Беремся за нестандартные кейсы'
          },
          {
            title: 'Экспертиза в IT',
            content: 'Глубоко понимаем специфику IT, говорим на одном языке'
          },
          {
            title: 'Всегда на связи',
            content: 'Круглосуточная забота о вашем бизнесе. Без отпусков и больничных'
          }
        ]
      },
      weDoIt: {
        info: {
          title: 'За ваше спокойствие отвечаем мы ',
          content: 'Наладим постоянную связь и возьмем дела в работу еще до подписания договора.',
        },
        blocks: [
          {title: 'Экспертное мнение и дистационная поддержка 24х7', content: 'Общаемся удобными вам способами Телеграм, Ватцап, Вайбер, звонки, мэйл.  '},
          {
            title: 'Подбирем специалистов с глубоким пониманием отрасли',
            content: 'Знание отраслевых особенностей каждой компании - клиента. Достоверность данных всегда подтверждается в ходе аудиторских проверок.  '
          },
        ]
      },
      clients: [
        {name: 'Just AI', logo: 'just-ai.svg', url: '#'},
        {name: 'Zillion Whales', logo: 'zillion-whales.svg', url: '#'},
        {name: 'LastBackend', logo: 'last-backend.svg', url: '#'},
        {name: 'i-Free', logo: 'ifree.svg', url: '#'},
        {name: 'Coin Keeper', logo: 'coin-keeper.svg', url: '#'},
        {name: 'Кошелёк', logo: 'wallet.svg', url: '#'},
        {name: 'Creative-Mobile', logo: 'cm.svg', url: '#'},
        {name: 'Ott Media', logo: 'ottmedia.png', url: '#'},
      ],
      helpSlides: {
        title: 'Поможем решить любые задачи',
        slides: [
          {
            title: 'Банковские операции',
            content: {
              list: [
                'Подготовка платежных поручений',
                'Разнесение и проведение поступлений на расчетные счета',
                'Ведение расчетных счетов',
                'Контроль правильности отражения банковских выписок на счетах бухгалтерского',
              ],
            }
          },
          {
            title: 'Ведение кассовых операций',
            content: {
              list: [
                'Составление авансовых отчетов на основании первичных документов, подтверждающих расходование денежных средств',
                'Отражение кассовых операций на счетах бухгалтерского и налогового учета.'
              ],
            }
          },
          {
            title: 'Ведение первичного учета по расчетам с контрагентами',
            content: {
              list: [
                'Ввод в учетную базу первичных документов по поступлению ТМЦ, работ, услуг',
                'Оформление/ввод в учетную базу первичных документов по реализации товаров, работ, услуг ',
                'Проверка первичных учетных документов на соответствие формы и содержания требований действующего законодательства',
                'Оформление и анализ актов сверки с контрагентами на основании данных учета в 1С по запросу',
              ],
            }
          },
          {
            title: 'Ведение учета финансовых вложений',
            content: {
              list: [
                'Учет финансовых вложений (выданные займы, доли и т.д.), процентов и дивидендов',
                'Отражение на счетах бухгалтерского и налогового учета операций по финансовым вложениям, полученным кредитам и займам.'
              ],
            }
          },
          {
            title: 'Ведение учета основных средств и нематериальных активов',
            content: {
              list: [
                'Оформление операций по поступлению, ремонту и модернизации основных средств в базе 1С',
                'Оформление операций документов по созданию НМА в базе 1С',
                'Отражение на счетах бухгалтерского и налогового учета операций по реализации и поступлению капитальных вложений, ОС, НМА',
                'Учет формирования себестоимости реализованных ОС, НМА в бухгалтерском и налоговом учете.'
              ],
            }
          },
          {
            title: 'Ведение учета расчетов с сотрудниками',
            content: {
              list: [
                'Начисление заработной платы в течение месяца сотрудникам (ТК и ГПХ), расчет налогов и фондов в начисленных сумм',
                'Расчет отпусков, больничных, пособий, компенсаций за неиспользованный отпуск',
                'Отражение на счетах бухгалтерского и налогового учета операций по учету заработной платы и иных выплат работникам',
                'Ведение налоговых карточек, карточек учета страховых взносов, лицевых счетов',
                'Подготовка отчетности по заработной плате, доходам сотрудников и индивидуальных сведений о страховых взносах',
              ],
            }
          },
          {
            title: 'Ведение кадрового делопроизводства',
            content: {
              list: [
                'Ведение кадрового учета',
                'оформление документов по приему и увольнению',
                'оформление приказов, связанных с сотрудниками',
                'формирование и отправка отчетов по КДП',
              ],
            }
          },
          {
            title: 'Составление бухгалтерской и налоговой отчетности',
            content: {
              list: [
                'Выполнение операций по закрытию отчетного периода',
                'Расчет финансовых результатов и налоговых обязательств Заказчика',
                'Составление годовой бухгалтерской отчетности',
                'Составление налоговой отчетности для организации на ОСН, УСН',
                'Подготовка статистической отчетности в органы Росстата',
                'Сдача отчетности по телекоммуникационным каналам связи',
                'Подготовка ответов на Требование из ИФНС, ФСС, ПФР, в части предоставления пояснений по сданной отчетности,  с подбором первичных документов, и отправка за отчетные периоды, в которых бухгалтерский и налоговый учет велся Исполнителем',
              ],
            }
          },
          {
            title: 'Методологическое и консультационное обеспечение',
            content: {
              list: [
                'Предварительная проверка договоров Заказчика с точки зрения бухгалтерского и налогового законодательства РФ ',
                'Подготовка образцов внутренних локальных документов организации, требующихся для целей бухгалтерского и налогового учета ',
                'Помощь в выборе оптимальной системы налогообложения ',
                'Консультации по бухгалтерскому и налоговому учету в рамках финансово-хозяйственной деятельности организации (письменно) ',
                'Методологическое обеспечение деятельности Заказчика ',
                'Подготовка ответов на запросы партнеров клиента ',
                'Участие и подготовка ответов на запросы в ходе аудиторских проверок.',
              ],
            }
          },
          {
            title: 'Взаимодействие с государственными органами',
            content: {
              list: [
                'Проведение сверки по начислениям и платежам с контролирующими органами',
                'Подготовка ответов на Требование из ИФНС, в части предоставления пояснений по сданной отчетности, без подбора первичных документов и отправка за отчетные периоды, в которых бухгалтерский и налоговый учет велся Исполнителем',
                'Коммуникации с ИНФС по причинам приостановления движения денежных средств по расчетным счетам',
                'Консультации в период проведения налоговых проверок государственными органами.',
              ],
            }
          },
          {
            title: 'Ведение Управленческого учета и отчетности',
            content: {
              list: [
                'Формирование еженедельного отчета по движению денежных средств',
                'Формирование отчетов по Дебиторской задолженности',
                'Формирование отчетность PL / CF / Bs',
                'Формирование утвержденных форм отчетности по запросам клиента.',
              ],
            }
          },
          {
            title: 'Дополнительные услуги',
            content: {
              list: [
                'Сопровождение внешнеэкономической деятельности',
                'Настройка интерфейса',
                'подготовка справок и не типовых документов',

                'взаимодействие с банками(открытие счетов / снятие ограничений / обработка запросов)',
              ],
            }
          },

        ]
      },
      bubbles: [
        'AI', 'FinTech', 'Разработка игр', 'Облачные решения', 'Трейдинг', 'Телеком', 'FoodTech'
      ],
      team: [
        {
          name: 'Елена Юманова',
          position: 'Руководитель i-Space.Finance',
          bio: 'Финансовый учет, управленческий учет и отчетность, управление бизнес процессами, автоматизация бизнес процессов, организация бизнеса с «0».',
          face: 'umanova.jpg'
        },
        {
          name: 'Светлана Долонская',
          position: 'Главный бухгалтер',
          bio: 'Сертифицированный бухгалтер, эксперт в области бухгалтерского и налогового учета.  Имеет успешный опыт прохождения аудиторских и налоговых проверок.',
          face: 'dolonskaya.jpg'
        },
        {
          name: 'Анна Петрова',
          position: 'Главный бухгалтер',
          bio: 'Более 18 лет успешного опыта работы  в сфере бухгалтерии, управления финансами и консалтинга  в российских и иностранных компаниях. ',
          face: 'petrova.jpg'
        }
      ],
      cases: {
        current: false,
        tasks: [
          {
            logo: 'nda',
            tittle: 'Восстановили учет и сэкономили 5 млн рублей',
            content:
                'Обнаружили в работе прошлого подрядчика большое количество неверных расчетов и ошибок. Оперативно восстановили весь учет. Закрыли риски по налогам на несколько миллионов рублей.'
          },
          {
            logo: 'nda',
            tittle: 'Применили налоговые льготы и повысили эффективность бизнеса',
            content: 'Проработали аналитику по учету и оптимизации налогов и помогли одному из клиентов вступить в ряды резидентов Инновационного центра «Сколково», обеспечив существенный рост эффективности бизнеса.'
          }
        ]
      },

    }
  }

}
</script>

<style scoped>

</style>
